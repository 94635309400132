import React from 'react';

const CloseButton = () => {

  return (
    <svg className="swiper-closer" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.423908 12.8689C0.345803 12.947 0.345802 13.0736 0.423907 13.1517L0.848171 13.576C0.926276 13.6541 1.05291 13.6541 1.13101 13.576L6.85858 7.84842C6.93668 7.77032 7.06332 7.77032 7.14142 7.84842L12.869 13.576C12.9471 13.6541 13.0737 13.6541 13.1518 13.576L13.5761 13.1517C13.6542 13.0736 13.6542 12.947 13.5761 12.8689L7.84853 7.14132C7.77042 7.06321 7.77042 6.93658 7.84853 6.85847L13.5761 1.13091C13.6542 1.0528 13.6542 0.926171 13.5761 0.848066L13.1518 0.423801C13.0737 0.345697 12.9471 0.345697 12.869 0.423802L7.14142 6.15137C7.06332 6.22947 6.93668 6.22947 6.85858 6.15137L1.13101 0.423802C1.05291 0.345697 0.926276 0.345697 0.848171 0.423801L0.423907 0.848066C0.345802 0.926171 0.345802 1.0528 0.423907 1.13091L6.15147 6.85847C6.22958 6.93658 6.22958 7.06321 6.15147 7.14132L0.423908 12.8689Z"/>
    </svg>
  );
};

export default CloseButton;
