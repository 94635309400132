import React from 'react';

// Data
import press from '../data/press.json';

class Component extends React.Component {
  render() {
    return (
        <div className='sans table-box grey'>  
        <div><h4 className='table-title uppercase'>features</h4></div>
        <table className='sans table-case'>
            <thead className='uppercase'>
            </thead>
            <tbody>
            {press.map((event, index) => (
                <tr key={index}>
                <td width="auto"><a href={event.link}>{event.title}</a></td>
                <td className='end'>{event.year}</td>
                </tr>
            ))}
            </tbody>
        </table>
      </div>
    );
  }
}

export default Component;