import React from 'react';

class ArrowDown extends React.Component {
  render() {
    return (
        <svg width="8" height="9" viewBox="0 0 8 11" fill="var(--color-5)"xmlns="http://www.w3.org/2000/svg">
            <path d="M4.49707 10.876L3.44971 10.876L3.44971 1.48633L1.46484 4.72363L7.3366e-08 4.72363L3.40576 -0.000488241L4.51172 -0.000488227L7.94678 4.72363L6.47461 4.72363L4.49707 1.48633L4.49707 10.876Z"/>
        </svg>
    )}
};
export default ArrowDown;