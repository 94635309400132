import React from 'react';

class ArrowDown extends React.Component {
  render() {
    return (
        <svg width="8" height="9" fill="var(--color-5)" viewBox="0 0 8 11" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.9502 -1.96573e-07L4.99756 -1.50791e-07L4.99756 9.38965L6.98242 6.15234L8.44727 6.15234L5.0415 10.8765L3.93555 10.8765L0.500488 6.15234L1.97266 6.15234L3.95019 9.38965L3.9502 -1.96573e-07Z" />
        </svg>
    )}
};
export default ArrowDown;