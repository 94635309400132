import './App.css';
import SwiperOverlay from './components/SwiperOverlay.js';
import ContentTables from './components/ContentTable.js'
import Intro from './components/Intro.js'

import React, { useRef } from 'react';

// Import Swiper styles
import 'swiper/css/pagination';


import './navigation.css';
import './pagination.css';
import './swiper.css';
import './style.css';


const Zazou = () => {
  return (
    <>
      <main>
        <SwiperOverlay/>
        <ContentTables/>
      </main>
    </>
  );
}

export default Zazou;
