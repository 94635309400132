import React from 'react';
import past from '../data/past-events.json'; // Import JSON data
import ArrowUp from '../components/arrowup.js'
import ArrowDown from '../components/arrowdown.js'

class Component extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: past, // Initialize data state with imported JSON data
            sortBy: null,
            sortAscending: true
        };
    }
    
    handleSort = (key) => {
        let data = this.state.data.slice();
        const sortAscending = this.state.sortBy === key ? !this.state.sortAscending : true;
    
        data.sort((a, b) => {
            if (a[key] < b[key]) return sortAscending ? -1 : 1;
            if (a[key] > b[key]) return sortAscending ? 1 : -1;
            return 0;
        });
    
        this.setState({
            data: data,
            sortBy: key,
            sortAscending: sortAscending
        });
    };

    renderSortIcon = (key) => {
        if (this.state.sortBy === key) {
            return this.state.sortAscending ? (
                <ArrowUp/>
            ) : (
                <ArrowDown/>
            );
        }
        return null;
    };

    render() {
        return (
            <div className='sans table-box grey'>  
            <div><h4 className='table-title uppercase'>solo</h4></div>
            <table className='sans table-case'>
                    <thead className='uppercase'>
                        <tr>
                            <th onClick={() => this.handleSort('title')}><a>Name {this.renderSortIcon('title')}</a></th>
                            <th className='float-m' onClick={() => this.handleSort('gallery')}><a>Gallery {this.renderSortIcon('gallery')}</a></th>
                            <th className='float-s' onClick={() => this.handleSort('location')}><a>Location {this.renderSortIcon('location')}</a></th>
                            <th className='end' onClick={() => this.handleSort('year')}><a>{this.renderSortIcon('year')} Year</a></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((event, index) => (
                            <tr key={index}>
                                <td width="auto"><a href={event.link}>{event.title}</a></td>
                                <td width="30%" className='float-m'><a href={event.link}>{event.gallery}</a></td>
                                <td width="20%" className='float-s'>{event.location}</td>
                                <td width="auto" className='end'><a href={event.link}>{event.year}</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Component;