import React from 'react';

// Data
import contact from '../data/contact.json';
import info from '../data/info.json';

class Component extends React.Component {
  render() {
    return (
        <div id="info" className='grey'>
        <div className='sans table-box flex-one'>  
            <div id='name'><h4 className='table-title uppercase'>zazou roddam</h4></div>
            <div className='table-textblock'>
                <h4>
                    <span className='grey'>{info[0].year}<br/>{info[0].about}</span>
                </h4>
            </div>
        </div>
        {/* <div className='sans table-box flex-one'>  
            <div><h4 className='table-title-half uppercase'>education</h4></div>
            <div className='table-textblock'>
                <h4 dangerouslySetInnerHTML={{__html: info[0].education}}>
                </h4>
            </div>
        </div> */}
        <div className='sans table-box flex-one'>  
        <div><h4 className='table-title-half uppercase'>contact</h4></div>
        <table className='table-case'>
            <thead className='uppercase'>
            </thead>
            <tbody className='contact'>
                {contact.map((event, index) => (
                <tr key={index}>
                    <td><a href={event.href}>{event.link}</a></td>
                </tr>
            ))}
            </tbody>
        </table>
      </div>
      </div>
    );
  }
}

export default Component;