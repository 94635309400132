import React from 'react';

// Data
import info from '../data/info.json';

class ContentTable extends React.Component {
  render() {
    return (
        <div className='info-box'>
            <p>{info[0].aboutborn}</p>
        </div>
    );
  }
}

export default ContentTable;