import React, { useRef, useEffect, useState } from 'react';

// styling
import '../content.css';

// Info Components
import Contact from '../components/Contact.js';
import Upcoming from '../components/Upcoming.js';
import PastSolo from '../components/PastSolo.js';
import PastGroup from '../components/PastGroup.js';
import Press from '../components/Press.js';

const ContentTable = () => {
    const containerRef = useRef(null);
    const [stickyIndex, setStickyIndex] = useState(null);
    const originalComponents = [
        <Contact key="contact1" />,
        // <Upcoming key="upcoming" />,
        <PastSolo key="past-solo" />,
        <PastGroup key="past-group" />,
        <Press key="press" />,
    ];

    // Cloning components at both ends for seamless looping
    const components = [
        ...originalComponents.map((comp, idx) => React.cloneElement(comp, { key: `clone-start-${idx}` })),
        ...originalComponents,
        ...originalComponents.map((comp, idx) => React.cloneElement(comp, { key: `clone-end-${idx}` }))
    ];

    const handleWheelScroll = (e) => {
        const container = containerRef.current;
        if (container && container.contains(e.target)) {
            e.preventDefault();
            container.scrollLeft += e.deltaY;

            const scrollWidth = container.scrollWidth / 3; 
            const maxScrollLeft = scrollWidth - container.clientWidth;

            if (container.scrollLeft >= 2 * scrollWidth) {
                container.scrollLeft = scrollWidth + (container.scrollLeft - 2 * scrollWidth);
            } else if (container.scrollLeft <= scrollWidth) {
                container.scrollLeft = scrollWidth + (container.scrollLeft - scrollWidth);
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollLeft = container.scrollWidth / 3; // Start in the middle (original content)
            container.addEventListener('wheel', handleWheelScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheelScroll);
            }
        };
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.getAttribute('data-index'), 10);
                        setStickyIndex(index);
                    }
                });
            },
            { root: container, threshold: 0, rootMargin: '0px -100% 0px 0px' } 
        );

        const stickyElements = container.querySelectorAll('.sticky');
        stickyElements.forEach((el) => observer.observe(el));

        return () => {
            stickyElements.forEach((el) => observer.unobserve(el));
        };
    }, [components]);

    return (
        <div className="grid" ref={containerRef}>
            {components.map((component, index) => (
                <div
                    key={index}
                    className={`grid-block sticky ${index === stickyIndex ? 'active' : ''}`}
                    data-index={index}
                >
                    {component}
                </div>
            ))}
        </div>
    );
};

export default ContentTable;
